// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-massages-js": () => import("./../../../src/pages/all-massages.js" /* webpackChunkName: "component---src-pages-all-massages-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-additional-pages-js": () => import("./../../../src/templates/AdditionalPages.js" /* webpackChunkName: "component---src-templates-additional-pages-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/CategoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-new-js": () => import("./../../../src/templates/New.js" /* webpackChunkName: "component---src-templates-new-js" */),
  "component---src-templates-practitioner-pages-js": () => import("./../../../src/templates/PractitionerPages.js" /* webpackChunkName: "component---src-templates-practitioner-pages-js" */)
}

